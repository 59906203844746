// set your env configs here
interface COMMON_CONFIG {
  sentryID: string;
  release: string;
  googleClientID: string;
  enableGlogin: boolean;
  enableEmail: boolean;
  enablePhone: boolean;
  sendOtpApiRoute?: string;
  submitOtpApiRoute?: string;
  initialTab?: string;
  authPrefix?: string;
  appType: string;
  logoutApiRoute: string;
  isRedxAdmin: boolean;
  emailApiRoute: string;
  forgotPwdApiRoute: string;
  resetPwdApiRoute: string;
}

interface CONFIG_TYPE extends COMMON_CONFIG {
  appEnv: 'development' | 'staging' | 'production' | string;
  enableReduxDevTool: boolean;
  enableSentry: boolean;
  licenseKey: string;
  applicationID: string;
}

const authPrefix = process.env.REACT_APP_AUTH_PREFIX + '';

const common: COMMON_CONFIG = {
  sentryID:
    'https://fb69154d944a47338a7b97333dbc20de@o362740.ingest.sentry.io/5555432',
  release: process.env.npm_package_version
    ? process.env.npm_package_version
    : '1.0.0',
  googleClientID:
    '144345706419-vdgjit2o2vhrsgn16vjniis1pi69s5g6.apps.googleusercontent.com',
  enableGlogin: process.env.REACT_APP_ENABLE_GOOGLE_LOGIN === 'true',
  enableEmail: process.env.REACT_APP_ENABLE_EMAIL_LOGIN === 'true',
  enablePhone: process.env.REACT_APP_ENABLE_PHONE_LOGIN === 'true',
  sendOtpApiRoute: process.env.REACT_APP_SEND_OTP_API_ROUTE || '',
  submitOtpApiRoute: process.env.REACT_APP_SUBMIT_OTP_API_ROUTE || '',
  initialTab: process.env.REACT_APP_INITIAL_TAB,
  authPrefix: authPrefix,
  appType: process.env.REACT_APP_TYPE || '',
  logoutApiRoute: process.env.REACT_APP_LOGOUT_API_ROUTE || '',
  isRedxAdmin: process.env.REACT_APP_TYPE + '' === 'redxadmin',
  emailApiRoute: process.env.REACT_APP_EMAIL_API_ROUTE || '',
  forgotPwdApiRoute: process.env.REACT_APP_FORGOT_PASSWORD_API_ROUTE || '',
  resetPwdApiRoute: process.env.REACT_APP_RESET_PWD_API || '',
};

const ENV = {
  dev: {
    ...common,
    appEnv: 'development',
    enableReduxDevTool: true,
    enableSentry: true,
    licenseKey: 'NRJS-78ee34c573f13655a68',
    applicationID: '978645822',
  },
  stage: {
    ...common,
    appEnv: 'staging',
    enableReduxDevTool: true,
    enableSentry: true,
    licenseKey: 'NRJS-78ee34c573f13655a68',
    applicationID: '978645822',
  },
  prod: {
    ...common,
    appEnv: 'production',
    enableReduxDevTool: false,
    enableSentry: true,
    licenseKey: 'NRJS-9f8380b90f74a1c70cc',
    applicationID: '450686781',
  },
};

const getEnvVars = (env: string | undefined): CONFIG_TYPE => {
  if (env === 'development') {
    return ENV.dev;
  } else if (env === 'staging') {
    return ENV.stage;
  } else {
    return ENV.prod;
  }
};

export default getEnvVars(process.env.REACT_APP_ENV);
