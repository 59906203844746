import { createStore, combineReducers, compose } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import type { Dispatch, MiddlewareAPI } from 'redux';
import { applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import panelReducer from './mainReducers';
import panelMiddleWare from './mainMiddleWares';
import { helpers } from '@shopuptech/ui-core';
import env from './variables';

import { reducers as reducersmainhome } from './modules/mainhome/reducers';
import { middlewares as middlewaresmainhome } from './modules/mainhome/middlewares';

const { traceReduxEnhancer } = helpers;
const errorTracer = traceReduxEnhancer();

const reducersMap: { [key: string]: any } = {};

type ActionMetaType = {
  moduleName?: string;
};
export type ActionType = {
  type: any;
  meta: ActionMetaType;
  payload?: any;
};

const getCurrentStore = (moduleName: string) => {
  return {
    getState: () => store.getState()[moduleName],
    dispatch: (act: ActionType) => {
      if (!act.meta) act.meta = {};
      act.meta.moduleName = act.meta.moduleName
        ? act.meta.moduleName
        : moduleName;
      store.dispatch(act);
    },
  };
};

const rootMiddleware = (store: MiddlewareAPI<any, any>) => (next: Dispatch) => (
  action: ActionType,
) => {
  const { meta: { moduleName } = {} } = action;
  if (!moduleName) return next(action);

  let combinedWares: Function[] = [];

  if (moduleName === 'mainhome') {
    combinedWares = [...middlewaresmainhome];
  }

  if (moduleName === 'main') {
    combinedWares = [...panelMiddleWare];
  }

  const enachancedWares = combinedWares.map((ware) =>
    ware(getCurrentStore(moduleName)),
  );

  const wares: Function = compose(...enachancedWares);

  return wares(next)(action);
};

reducersMap['main'] = (state: any = {}, action: ActionType) => {
  const { meta: { moduleName = '' } = {} } = action;
  if (moduleName !== 'main') return state;
  else return panelReducer(state, action);
};

reducersMap['mainhome'] = (state: any = {}, action: ActionType) => {
  const { meta: { moduleName = '' } = {} } = action;
  if (moduleName !== 'mainhome') return state;
  else return reducersmainhome(state, action);
};

const middlewares = applyMiddleware(thunk, rootMiddleware);

const mainReducers = combineReducers(reducersMap);

const composedMiddlware = env.enableReduxDevTool
  ? composeWithDevTools(middlewares)
  : compose(middlewares, errorTracer);

export type RootState = ReturnType<typeof mainReducers>;

const store = createStore(mainReducers, {}, composedMiddlware);
export default store;
