import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Formik } from 'formik';
import {
  Card,
  Flex,
  StyleSheet,
  InputText,
  Button,
  ErrorMessage,
  Toast,
} from '@shopuptech/expo-shopup-ui';
import classNames from 'classnames/bind';
import styles from './login.module.css';
import { useDispatch } from 'react-redux';
import { resetPwdInitAction } from './LoginStore/loginActions';
import { types } from '@shopuptech/ui-core';
import { LOGIN_PATH } from '../../../Navigation';

const cx = classNames.bind(styles);

interface ParamTypes {
  token?: string;
}

type FormInitialType = {
  password: string;
  passwordConfirmation: string;
};

const initialValues: FormInitialType = {
  password: '',
  passwordConfirmation: '',
};

const uikitStyle = StyleSheet.create({
  inputFields: {
    marginVertical: 12,
  },
});

const handleValidate = (values: FormInitialType) => {
  const error: Partial<FormInitialType> = {};
  if (!values.password) {
    error.password = 'This field is required';
  }

  if (!values.passwordConfirmation) {
    error.passwordConfirmation = 'This field is required';
  }

  if (values.password && values.password.length < 8) {
    error.password = 'Password should have a minimum of 8 characters';
  }

  if (
    values.password &&
    values.passwordConfirmation &&
    values.password !== values.passwordConfirmation
  ) {
    error.passwordConfirmation = 'Passwords should be equal';
  }

  return error;
};

const ForgotPassword = () => {
  const { token } = useParams<ParamTypes>();
  const history = useHistory();
  const dispatch: types.Dispatch<any> = useDispatch();
  const handleFormSubmit = (values: FormInitialType) => {
    if (token) {
      dispatch(resetPwdInitAction({ ...values, token }))
        .then(() => {
          Toast('Password has been successfully reset!!!');
          history.replace(LOGIN_PATH);
        })
        .catch((error) => handleErrorToast(error));
    } else {
      Toast('Url is invalid');
    }
  };

  const handleErrorToast = (error: any) => {
    if (error && error.error && error.error.message) {
      Toast(error.error.message);
    }
  };

  return (
    <div>
      <Flex center middle>
        <div className={cx('forgotPwdContainer')}>
          <Card normal align={'stretch'} disabled>
            <div className={cx('fpinnerContainer')}>
              <Formik
                initialValues={initialValues}
                onSubmit={handleFormSubmit}
                validate={handleValidate}>
                {({ values, handleChange, errors, touched, handleSubmit }) => {
                  return (
                    <>
                      <InputText
                        overrideStyle={uikitStyle.inputFields}
                        secureTextEntry
                        label={'Password'}
                        value={values.password}
                        onChange={handleChange('password')}
                      />
                      <ErrorMessage
                        name={'password'}
                        errors={errors}
                        touched={touched}
                      />
                      <InputText
                        overrideStyle={uikitStyle.inputFields}
                        secureTextEntry
                        label={'Confirm Password'}
                        value={values.passwordConfirmation}
                        onChange={handleChange('passwordConfirmation')}
                      />
                      <ErrorMessage
                        name={'passwordConfirmation'}
                        errors={errors}
                        touched={touched}
                      />
                      <Button onClick={handleSubmit}>Reset Password</Button>
                    </>
                  );
                }}
              </Formik>
            </div>
          </Card>
        </div>
      </Flex>
    </div>
  );
};

export default ForgotPassword;
