import {
  CHECK_LOGIN,
  CHECK_LOGIN_SUCCESS,
  CHECK_LOGIN_ERROR,
  CHECK_PERMISSIONS_INIT,
  CHECK_PERMISSIONS_SUCCESS,
  CHECK_PERMISSIONS_ERROR,
  LOGOUT_INIT,
  LOGOUT_SUCCESS,
  LOGOUT_ERROR,
} from './mainActions';
import type { Middleware, Dispatch, MiddlewareAPI } from 'redux';
import type {
  CheckLoginAllAction,
  CheckLoginResponse,
  CheckPermissionsAllAction,
  PermissionResponse,
  LogoutAllAction,
  CheckLoginRedxResponse,
} from './mainAction.types';
import { helpers } from '@shopuptech/ui-core';
import { Toast } from '@shopuptech/expo-shopup-ui';
import env from './variables';
const { apiMiddleWare, handleErrorMiddleWare, trackError } = helpers;

type GenericActionType = {
  meta: {
    moduleName: string;
  };
  payload: string;
  type: string;
};

type CheckLoginCommonResponse = CheckLoginResponse | CheckLoginRedxResponse;

const checkLoginRedxTypeGaurd = (res: CheckLoginCommonResponse) => {
  if (res && res.data && 'ID' in res.data) {
    const typedRes = res as CheckLoginRedxResponse;
    return typedRes;
  }
  return null;
};

const hostUrl = window.location.origin;
const headers = new Headers();
headers.append('Content-Type', 'application/json');
const checkLoginMiddleware: Middleware = (store: MiddlewareAPI<any, any>) => (
  next: Dispatch,
) => (
  action: CheckLoginAllAction | CheckPermissionsAllAction | LogoutAllAction,
) => {
  const result = next(action);
  switch (action.type) {
    case CHECK_LOGIN:
      return fetch(hostUrl + env.authPrefix + '/user')
        .then((response) => response.json())
        .then(handleErrorMiddleWare)
        .then(
          (response: CheckLoginCommonResponse) => {
            const { data = {} } = response || {};
            let payload = {
              ...data,
            };
            const redxResponse = checkLoginRedxTypeGaurd(response);
            if (redxResponse) {
              const {
                data: { FULL_NAME, PHONE_NUMBER },
              } = redxResponse;
              payload = {
                ...data,
                id: redxResponse.data.ID,
                name: FULL_NAME,
                phone: PHONE_NUMBER,
              };
            }
            store.dispatch({
              type: CHECK_LOGIN_SUCCESS,
              payload: payload,
              meta: {
                moduleName: 'main',
              },
            });
            return Promise.resolve(response);
          },
          (err) => {
            console.log('err: ', err);
            let payload = err && err.error ? err.error.message : '';
            payload =
              err && err.body && err.body.message ? err.body.message : '';
            store.dispatch({
              type: CHECK_LOGIN_ERROR,
              payload: payload,
              meta: {
                moduleName: 'main',
              },
            });
            return Promise.reject(err);
          },
        )
        .catch((error) => {
          store.dispatch({
            type: CHECK_LOGIN_ERROR,
            error: error.message,
            meta: {
              moduleName: 'main',
            },
          });
          return Promise.reject(error);
        });
    case CHECK_PERMISSIONS_INIT:
      return fetch(hostUrl + env.authPrefix + '/user/permissions')
        .then(apiMiddleWare)
        .then(handleErrorMiddleWare)
        .then((response: PermissionResponse) => {
          store.dispatch({
            type: CHECK_PERMISSIONS_SUCCESS,
            payload:
              response && response.data && response.data.items
                ? response.data.items
                : [],
            meta: {
              moduleName: 'main',
            },
          });
          return Promise.resolve(response);
        })
        .catch((err) => {
          const errorMessage =
            err && err.error
              ? err.error.message
              : 'Failed to fetch permissions';
          store.dispatch({
            type: CHECK_PERMISSIONS_ERROR,
            payload: errorMessage,
            meta: {
              moduleName: 'main',
            },
          });
          Toast(errorMessage);
          return Promise.reject(errorMessage);
        });
    case LOGOUT_INIT:
      return fetch(hostUrl + env.logoutApiRoute, {
        method: env.isRedxAdmin ? 'GET' : 'POST',
        headers: headers,
        body: env.isRedxAdmin ? undefined : JSON.stringify({}),
      })
        .then((response) => {
          const contentType = response.headers.get('content-type');
          console.log('contentType: ', contentType);
          if (contentType?.includes('json')) {
            return Promise.reject(response.json());
          }
          return response;
        })
        .then(() => {
          store.dispatch({
            type: LOGOUT_SUCCESS,
            payload: 'logout success',
            meta: {
              moduleName: 'main',
            },
          });
          return Promise.resolve({ message: 'logout success' });
        })
        .catch((err) => {
          store.dispatch({
            type: LOGOUT_ERROR,
            payload: err.error ? err.error.message : '',
            meta: {
              moduleName: 'main',
            },
          });
          return Promise.reject(err);
        });
  }
  return result;
};

const ErrorMiddleWares: Middleware = (store: MiddlewareAPI<any, any>) => (
  next: Dispatch,
) => (action: GenericActionType) => {
  const result = next(action);
  if (
    action.type.includes('_ERROR') &&
    action.type !== CHECK_LOGIN_ERROR &&
    action.type !== CHECK_PERMISSIONS_ERROR
  ) {
    trackError({
      error: { ...action },
      tags: {
        api: 'errors',
      },
      severity: 'Error',
    });
  }
  return result;
};

const mainMiddleWares = [checkLoginMiddleware, ErrorMiddleWares];

export default mainMiddleWares;
